<script lang="ts">
    import Fa from "svelte-fa";
    import { _ } from "svelte-i18n";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

    import { onMount } from "svelte";
    import FiltersSidebar from "../../../components/filters/FiltersSidebar.svelte";
    import Selector from "../../../components/Elements/Selector.svelte";
    import {
        actions as customerActions,
        buyerRecourseLimit,
        buyerRecourseLimitMaxAllowed,
        buyerRecourseLimitLoading,
        buyerRecourseLimitMaxAllowedLoading
    } from "../../../store/merchant/customers.store";
    import modalState from "../../../store/modals.store";
    import {
        account,
        signupComplete,
        recourseEnabled
    } from "../../../store/merchant/account.store";
    import ToolTip from "../../../components/tooltip/ToolTip.svelte";
    import ActiveToolTip from "../../../components/tooltip/ActiveToolTip.svelte";
    import Input from "../../../components/Elements/InputBox.svelte";
    import Switch from "../../../components/Elements/Switch.svelte";
    import Checkbox from "../../../components/Elements/Checkbox.svelte";
    import type { ICustomer, ICustomerRecourseLimit, CurrencyCode } from "../../../static/types";
    import Icon from "../../../components/icons/Icon.svelte";
    import utils, { type CurrencyFormatOptions } from "../../../utilsV2/currency";
    import { titleCase } from "../../../utilsV2/helpers";
    import { getCurrency } from "../../../utils/functions";
    import {
        validateCustomerRecourseAmount,
        validateCustomerRecourseAmountWithMaxAllowed
    } from "../../../utils/validation";
    import SignupIncompleteModal from "../../../components/modals/AccountSetup/SignupIncompleteModal.svelte";
    import environment from "@/services/environment";

    export let selectedCustomer: ICustomer;
    export let recourseRelatedData: any;
    export let autoScrollSettings: boolean = false;

    const RECOURSE_FALLBACK_TOOLTIP_TEXT = $_(
        "customers.combined.settings.recourseFallbackTooltip"
    );
    const RECOURSE_FALLBACK_CUSTOMER_TOOLTIP_TEXT = $_(
        "customers.combined.settings.recourseFallbackCustomerTooltip"
    );
    const PAYMENT_TERMS_TOOLTIP_TEXT = $_("customers.combined.settings.paymentTermsTooltip");
    const SET_RECOURSE_LIMIT_TOOLTIP_TEXT = $_("customers.combined.settings.setRecourseTooltip");

    let buyerRecourseLimitAlreadySet: boolean = true;
    let buyerRecourseLimitDisplay: ICustomerRecourseLimit;
    let updatedBuyerRecourseLimitAmount: string;
    let invalidRecourseAmountError: string = "";

    let isActiveRecourseInputField: boolean = false;

    let paymentTerm: string = selectedCustomer?.due_in_days + " days";
    const paymentTermsOptions = ["14 days", "21 days", "30 days", "45 days", "60 days", "90 days"];

    let recourseFallbackEnabled: boolean = false;

    // Guaranteed checkout related states
    let guaranteedCheckoutEnabled: boolean = selectedCustomer?.direct_invoice_fallback;
    let guaranteedCheckoutLimit: string = selectedCustomer?.fallback_threshold;
    let guaranteedCheckoutAcceptRisk = false;
    let guaranteedCheckoutAcceptRiskBorderColor = "#D0D5DD";

    let buyerFallbackEnabledOnMerchant = false;
    let merchantCurrencyCode = "GBP";
    let customerCurrencyCode: CurrencyCode = "GBP";
    let invalidLimitInput = false;

    let isValidateCustomerRecourseLimit = false;

    onMount(() => {
        if (autoScrollSettings) {
            const elem = document.getElementsByClassName(
                "filter-sidebar-main"
            )[0] as HTMLDivElement;
            scrollToRecourseAmountField(elem);
            const inputElem = document.getElementById("buyer-recourse-amount") as HTMLInputElement;
            inputElem.focus();
        }
    });

    $: {
        buyerFallbackEnabledOnMerchant = $account?.flags?.buyer_fallback_enabled;
        merchantCurrencyCode = $account?.feeCurrency;
        isValidateCustomerRecourseLimit =
            $account?.flags?.enable_fe_validate_customer_recourse_limit;
    }

    $: customerCurrencyCode =
        $buyerRecourseLimit?.currency || getCurrency(selectedCustomer?.buyer_country_code);

    function handleClickPostAction(action) {
        if ($signupComplete) {
            return action();
        }
        modalState.actions.setModal("", {}, SignupIncompleteModal);
    }

    // eslint-disable-next-line
    function validateCustomerSaveSettings() {
        let valid = true;
        if (guaranteedCheckoutLimit !== null) {
            guaranteedCheckoutLimit = guaranteedCheckoutLimit.replace(/,/g, "");
        }
        if (guaranteedCheckoutEnabled && buyerFallbackEnabledOnMerchant) {
            if (isNaN(parseInt(guaranteedCheckoutLimit))) {
                invalidLimitInput = true;
                valid = false;
            } else {
                invalidLimitInput = true;
            }

            if (!guaranteedCheckoutAcceptRisk) {
                guaranteedCheckoutAcceptRiskBorderColor = "red";
                valid = false;
            } else {
                guaranteedCheckoutAcceptRiskBorderColor = "#D0D5DD";
            }
        }

        if (valid) {
            customerActions.updateCustomerSettings({
                due_in_days: Number(paymentTerm.split(" ")[0]),
                buyer_company_name: selectedCustomer?.buyer_company_name,
                buyer_organization_number: selectedCustomer?.buyer_organization_number,
                buyer_country_code: selectedCustomer?.buyer_country_code,
                direct_invoice_fallback: guaranteedCheckoutEnabled,
                fallback_threshold:
                    guaranteedCheckoutLimit === "" ||
                    guaranteedCheckoutLimit === null ||
                    parseInt(guaranteedCheckoutLimit) === 0
                        ? null
                        : parseInt(guaranteedCheckoutLimit).toString()
            });

            // check if feature flag is disabled or if enabled then max allowed limit is required
            if (
                $recourseEnabled &&
                (!isValidateCustomerRecourseLimit ||
                    (isValidateCustomerRecourseLimit && $buyerRecourseLimitMaxAllowed))
            ) {
                const data: ICustomerRecourseLimit = {
                    buyer_organization_number: selectedCustomer?.buyer_organization_number,
                    buyer_country_code: selectedCustomer?.buyer_country_code,
                    max_amount: recourseFallbackEnabled ? updatedBuyerRecourseLimitAmount : "0",
                    currency:
                        $buyerRecourseLimit?.currency ||
                        getCurrency(selectedCustomer?.buyer_country_code)
                };

                // check if merchant is enabling the recourse fallback for this customer first time
                if (!buyerRecourseLimitAlreadySet) {
                    modalState.actions.setModal("CONFIRM_ENABLE_CUSTOMER_RECOURSE_FALLBACK", {
                        data,
                        customer_name: selectedCustomer?.buyer_company_name
                    });
                } else {
                    customerActions.updateCustomerRecouseLimit(
                        data,
                        selectedCustomer?.buyer_company_name,
                        buyerRecourseLimitAlreadySet, // if customer recourse limit is already being added on BE table
                        !recourseFallbackEnabled,
                        $_
                    );
                }
            }
        }
    }

    const scrollToRecourseAmountField = async (node: HTMLDivElement) => {
        node.scroll({ top: node.scrollHeight, behavior: "smooth" });
    };

    const formatCustomerMoney = (money: string | number, options?: CurrencyFormatOptions) =>
        utils.formatCurrency(customerCurrencyCode)(money, options);

    const formatMerchantMoney = (money: string | number, options?: CurrencyFormatOptions) =>
        utils.formatCurrency(recourseRelatedData?.recourseCurrency)(money, options);

    function toggleSettings() {
        customerActions.toggleSettings(false);
    }

    $: if (
        $buyerRecourseLimit?.error_code &&
        $buyerRecourseLimit?.error_code == "MERCHANT_BUYER_RECOURSE_LIMIT_NOT_FOUND_ERROR"
    ) {
        buyerRecourseLimitAlreadySet = false;
        buyerRecourseLimitDisplay = undefined;
        updatedBuyerRecourseLimitAmount = "0";
    } else {
        if ($buyerRecourseLimit?.merchant_id) {
            buyerRecourseLimitAlreadySet = true;
            buyerRecourseLimitDisplay = $buyerRecourseLimit;
            updatedBuyerRecourseLimitAmount = $buyerRecourseLimit?.merchant_buyer_recourse_limit;
        }
    }

    $: recourseFallbackEnabled = parseFloat(updatedBuyerRecourseLimitAmount) > 0 ? true : false;

    $: invalidRecourseAmountError =
        isValidateCustomerRecourseLimit && $buyerRecourseLimitMaxAllowed
            ? validateCustomerRecourseAmountWithMaxAllowed(
                  updatedBuyerRecourseLimitAmount,
                  $buyerRecourseLimitMaxAllowed,
                  customerCurrencyCode,
                  $_
              )
            : validateCustomerRecourseAmount(
                  updatedBuyerRecourseLimitAmount,
                  customerCurrencyCode,
                  $_
              );
</script>

<FiltersSidebar>
    <div slot="header" class="filter-header">
        <div>
            <span>{$_("customers.combined.settings.customerSettings")}</span>
            {#if $buyerRecourseLimitLoading || $buyerRecourseLimitMaxAllowedLoading}
                <sup class="text-xs">{$_("components.loading")}</sup>
            {/if}
        </div>
        <div class="close pt-1">
            <button on:click={toggleSettings}>
                <Icon name="cross" fill={"#98A2B3"} size={24} />
            </button>
        </div>
    </div>
    <div slot="main">
        <div class="filter-item">
            <span class="filter-item-header-text"
                >{$_("customers.combined.settings.customerDetails")}</span
            >
            <div class="flex-details">
                <div class="flex">
                    <div>
                        <p class="text-sm ctn-general-tertiary">
                            {$_("customers.combined.settings.businessName")}
                        </p>
                        <h3 class="text-lg ctn-general-primary font-bold">
                            {titleCase([selectedCustomer?.buyer_company_name])[0]}
                        </h3>
                    </div>
                    <div>
                        <p class="text-sm text-right ctn-general-secondary">
                            {$_("customers.combined.settings.orgNumber")}: {selectedCustomer?.buyer_organization_number}
                        </p>
                    </div>
                </div>
                <hr class="mt-2 mb-2" />
                <div class="flex">
                    <div>{$_("customers.combined.settings.currentCreditLimit")}</div>
                    <div>
                        <p class="text-right font-bold">
                            {formatCustomerMoney(selectedCustomer?.credit_limit || 0)}
                        </p>
                    </div>
                </div>
                <div class="flex">
                    <div>{$_("customers.combined.settings.remainingCreditLimit")}</div>
                    <div>
                        <p class="text-right font-bold">
                            {formatCustomerMoney(selectedCustomer?.credit_limit_balance || 0)}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="filter-item">
            <div class="filter-item-header-text">
                {$_("customers.combined.settings.updatePaymentTerms")}
                <div class="tooltip-container">
                    <ToolTip width="250px" position="top" content={PAYMENT_TERMS_TOOLTIP_TEXT}>
                        <Fa size="md" icon={faCircleQuestion} color="var(--primary-500)" />
                    </ToolTip>
                </div>
            </div>
            <span>{$_("customers.combined.settings.paymentTerms")}</span>
            <div class="dueInDaysSelector">
                <Selector
                    value={paymentTerm}
                    showArrow={true}
                    on:change={(e) => {
                        paymentTerm = e.detail.value;
                    }}
                    options={paymentTermsOptions}
                    backgroundColor="white"
                />
            </div>
        </div>

        <!-- Check if merchant recourse fallback is enabled on BE -->
        {#if $recourseEnabled}
            <div class="filter-item">
                <div class="filter-item-header-text">
                    {$_("customers.combined.settings.recourseFallback")}
                    <div class="tooltip-container">
                        <ToolTip
                            width="250px"
                            position="top"
                            content={RECOURSE_FALLBACK_TOOLTIP_TEXT}
                        >
                            <Fa size="md" icon={faCircleQuestion} color="var(--primary-500)" />
                        </ToolTip>
                    </div>
                    <div class="float-right">
                        <span class="font-normal text-sm mr-1">
                            {recourseFallbackEnabled
                                ? $_("customers.combined.settings.enabled")
                                : $_("customers.combined.settings.disabled")}
                        </span>
                        <Switch
                            checked={recourseFallbackEnabled}
                            on:change={(e) => {
                                recourseFallbackEnabled = e.detail.checked;
                                isActiveRecourseInputField =
                                    e.detail.checked &&
                                    !(parseFloat(updatedBuyerRecourseLimitAmount) > 0);
                            }}
                        />
                    </div>
                </div>
                <span>
                    {$_("customers.combined.settings.recourseFallbackDescription", {
                        values: {
                            customerName: titleCase([selectedCustomer?.buyer_company_name])[0],
                            brandName: environment.branding.displayName,
                            totalRecourseLimit: formatMerchantMoney(
                                recourseRelatedData?.totalRecourseLimit
                            )
                        }
                    })}
                </span>
                <div class="flex-details">
                    <div class="flex">
                        <div>
                            <h3 class="text-lg ctn-general-primary font-bold">
                                {@html $_("customers.recourseFallbackDetailsTitle")}
                            </h3>
                        </div>
                    </div>
                    <hr class="mt-2 mb-2" />
                    <div class="flex">
                        <div>{$_("customers.combined.settings.totalRecourseLimit")}</div>
                        <div>
                            <p class="text-right font-bold">
                                {formatMerchantMoney(recourseRelatedData?.totalRecourseLimit)}
                            </p>
                        </div>
                    </div>
                    <div class="flex">
                        <div>{$_("customers.combined.settings.currentRecourseExposure")}</div>
                        <div>
                            <p class="text-right font-bold">
                                {formatMerchantMoney(recourseRelatedData?.currentRecourseExposure)}
                            </p>
                        </div>
                    </div>
                    <div class="flex">
                        <div>{$_("customers.combined.settings.remainingRecourseLimit")}</div>
                        <div>
                            <p class="text-right font-bold">
                                {formatMerchantMoney(recourseRelatedData?.remainingRecourseLimit)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="filter-item">
                <ActiveToolTip
                    isActive={isActiveRecourseInputField}
                    width="320px"
                    position="top"
                    content={SET_RECOURSE_LIMIT_TOOLTIP_TEXT}
                >
                    <div>
                        {$_("customers.combined.settings.recourseLimitForCustomer", {
                            values: {
                                customerName: titleCase([selectedCustomer?.buyer_company_name])[0]
                            }
                        })}
                        <div class="tooltip-container">
                            <ToolTip
                                width="250px"
                                position="top"
                                content={RECOURSE_FALLBACK_CUSTOMER_TOOLTIP_TEXT}
                            >
                                <Fa size="md" icon={faCircleQuestion} color="var(--primary-500)" />
                            </ToolTip>
                        </div>
                    </div>
                </ActiveToolTip>

                <div class="relative with-type">
                    <Input
                        id="buyer-recourse-amount"
                        disabled={$buyerRecourseLimitLoading ||
                            $buyerRecourseLimitMaxAllowedLoading ||
                            (isValidateCustomerRecourseLimit && !$buyerRecourseLimitMaxAllowed)}
                        type="text"
                        value={$buyerRecourseLimitLoading || $buyerRecourseLimitMaxAllowedLoading
                            ? $_("components.loading")
                            : buyerRecourseLimitDisplay?.merchant_buyer_recourse_limit}
                        placeholder="20,000.00"
                        on:change={(e) => {
                            updatedBuyerRecourseLimitAmount = e.detail.value;
                            if (parseFloat(updatedBuyerRecourseLimitAmount) > 0) {
                                isActiveRecourseInputField = false;
                            }
                        }}
                    />
                    <span class="item-type">{customerCurrencyCode}</span>
                </div>

                {#if invalidRecourseAmountError.length}
                    <p class="text-sm text-red-500">{invalidRecourseAmountError}</p>
                {:else}
                    <p class="text-sm text-gray-500">
                        {$_("customers.combined.settings.recourseLimitDescription", {
                            values: {
                                customerName: titleCase([selectedCustomer?.buyer_company_name])[0]
                            }
                        })}
                    </p>
                {/if}
            </div>
        {/if}

        {#if buyerFallbackEnabledOnMerchant}
            <div class="filter-item">
                <div class="filter-item-header-text">
                    {$_("customers.combined.settings.guaranteedCheckout")}
                    <div class="float-right">
                        <span class="font-normal text-sm mr-1">
                            {guaranteedCheckoutEnabled
                                ? $_("customers.combined.settings.enabled")
                                : $_("customers.combined.settings.disabled")}
                        </span>
                        <Switch bind:checked={guaranteedCheckoutEnabled} />
                    </div>
                </div>
                <span>
                    {$_("customers.combined.settings.guaranteedCheckoutTooltip", {
                        values: { brandName: environment.branding.displayName }
                    })}
                </span>
                {#if guaranteedCheckoutEnabled}
                    <div class="mb-6 mt-6">
                        <div class="flex flex-row justify-between">
                            <p class="text-sm text-gray-500 break-words max-w-xs">
                                <span class="text-base text-black"
                                    >{$_(
                                        "customers.combined.settings.guaranteedCheckoutLimit"
                                    )}</span
                                >
                                <br />
                                {($_("customer.guaranteedCheckoutLimitDescription"),
                                { values: { currencyCode: merchantCurrencyCode } })}
                            </p>
                            <div class="relative with-type w-1/3">
                                <Input
                                    type="text"
                                    bind:value={guaranteedCheckoutLimit}
                                    placeholder="1000"
                                    invalid={invalidLimitInput}
                                    showInvalidError={false}
                                />
                                <span class="item-type">{merchantCurrencyCode}</span>
                            </div>
                        </div>
                    </div>
                    <div class="guaranteedCheckoutCheckbox text-sm flex flex-row items-center">
                        <Checkbox
                            bind:checked={guaranteedCheckoutAcceptRisk}
                            borderColor={guaranteedCheckoutAcceptRiskBorderColor}
                            showCircle={false}
                        />
                        <p class="guaranteedCheckoutConfirmation">
                            {$_("customers.combined.settings.guaranteedCheckoutAcceptRisk")}
                        </p>
                    </div>
                {/if}
            </div>
        {/if}
    </div>
    <div slot="footer" class="filter-footer">
        <button
            class="apply-button"
            class:disabled={invalidRecourseAmountError.length ||
                $buyerRecourseLimitLoading ||
                $buyerRecourseLimitMaxAllowedLoading}
            on:click={() => handleClickPostAction(validateCustomerSaveSettings)}
        >
            {$_("customers.combined.settings.saveSettings")}
        </button>
        <button class="clear-button" on:click={toggleSettings}>{$_("components.cancel")}</button>
    </div>
</FiltersSidebar>

<style>
    .filter-header {
        display: flex;
        justify-content: space-between;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        color: var(--ctnGeneralPrimary);
    }

    .filter-footer {
        display: flex;
        gap: 0.5rem;
    }

    .apply-button {
        color: white;
        width: 12.25rem;
        height: 3rem;
        line-height: 3rem;
        background: var(--primary-500);
        border: 1px solid var(--primary-500);
        box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
        border-radius: 8px;
        text-align: center;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0.5px;
        cursor: pointer;
    }
    .apply-button:hover {
        background: var(--primary-600);
    }

    .apply-button.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .clear-button {
        width: 6.125rem;
        height: 3rem;
        line-height: 3rem;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
        border-radius: 8px;
        text-align: center;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: var(--ctnInteractiveGeneralDefault);
        cursor: pointer;
    }
    .clear-button:hover {
        background: var(--bgInteractiveSemanticHighlightHoverSecondary);
    }

    .filter-item-header-text {
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: var(--ctnGeneralPrimary);
    }
    .filter-item {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 5px 0 30px 0;
        margin: 0 0 1rem 0;
        gap: 0.8rem;
    }

    .flex-details {
        padding: 15px;
        border-radius: 10px;
        background-color: #f9fafb;
        display: flex;
        flex-direction: column;
    }

    .flex-details > .flex {
        flex-direction: row;
        align-items: flex-end;
        padding: 5px 0 0 0;
    }

    .flex-details > .flex > div {
        flex: auto;
    }

    .tooltip-container {
        display: inline-block;
        margin: auto 0.5rem;
        font-size: 14px;
    }

    .guaranteedCheckoutCheckbox {
        background-color: #fffaeb;
        padding: 12px;
        border-radius: 8px;
    }

    .guaranteedCheckoutConfirmation {
        color: #93370d;
    }
</style>

<script lang="ts">
    import { _ } from "svelte-i18n";
    import { uniqueId } from "lodash";
    import Fa from "svelte-fa";
    import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

    import { account } from "@/store/merchant/account.store";
    import modalState from "@/store/modals.store";
    import orderCreateStore, { selectedCompanyName } from "@/store/merchant/orderCreate.store";
    import { buyerDetails, orderReplica } from "@/store/merchant/allOrders.store";
    import { getBuyerCountries } from "@/static/customers";
    import BuyerCountryExpansionModal from "@/components/modals/BuyerCountryExpansionModal.svelte";
    import CountrySelector from "@/components/countrySelector/CountrySelector.svelte";
    import Selector from "@/components/Elements/Selector.svelte";
    import CompanySelector from "@/components/companySelector/CompanySelector.svelte";

    export let countryUpdated;
    export let companySearchText;
    export let onCurrencyChange;
    export let currency;
    export let address;
    export let companyID;
    export let companyExternalID;
    export let invalidSelectCompany;
    export let countryCode;

    let isCrossBorderEnabled: boolean = false;
    let availableCountries = {};
    let countryPickerKey = uniqueId();
    let customerCountry: any = {};

    // Subscribe to merchant account data
    $: if (
        $account?.buyer_country_configurations?.length > 1 &&
        $account.flags.cross_border_enabled
    ) {
        isCrossBorderEnabled = true;
        availableCountries = getBuyerCountries($account.buyer_country_configurations);
    }

    $: if (customerCountry == undefined) {
        modalState.actions.setModal("", {}, BuyerCountryExpansionModal);
        customerCountry = {};
        countryPickerKey = uniqueId();
    }

    $: if (customerCountry?.country_code) {
        countryCode = customerCountry.country_code;
        orderCreateStore.actions.setCountry(customerCountry);
        countryUpdated();
    }

    $: if (companySearchText) selectedCompanyName.set(companySearchText);
</script>

<div
    class="company-search-container mb-8 w-full grid gap-4 justify-between items-end grid-cols-[0.25fr_0.25fr_0.25fr_0.25fr]"
>
    {#if isCrossBorderEnabled}
        {#key countryPickerKey}
            <div class="text-sm" style="grid-column: span 1;">
                <CountrySelector
                    bind:country={customerCountry}
                    countries={availableCountries}
                    size="42px"
                    initialCountryCode={$orderReplica?.country
                        ? $orderReplica?.country
                        : $buyerDetails?.countryCode
                          ? $buyerDetails?.countryCode
                          : $account.countryCode}
                    on:change={() => {
                        companySearchText = "";
                        selectedCompanyName.set("");
                    }}
                    required
                    notOnListText={$_("buyerDetails.getMoreCountries")}
                    notOnListTextColor="var(--primary-500)"
                    showNotOnList={true}
                />
            </div>
        {/key}
        {#if customerCountry?.buyerCurrencies?.length > 1}
            {#key customerCountry}
                <div style="grid-column:span 1;">
                    <Selector
                        size="42px"
                        label={$_("buyerDetails.currency")}
                        options={customerCountry.buyerCurrencies}
                        bind:value={currency}
                        placeholder={currency}
                        showArrow={true}
                        on:change={onCurrencyChange}
                        required
                    />
                </div>
            {/key}
        {/if}
    {/if}
    <div class="relative search-container col-span-2">
        <div class="absolute search-icon bottom-[0.8rem] left-4 z-10">
            <Fa icon={faMagnifyingGlass} color="var(--ctnGeneralTertiary)" />
        </div>
        <CompanySelector
            bind:countryCode
            showId={false}
            bind:address
            bind:company={companySearchText}
            bind:companyID
            bind:companyExternalID
            invalid={invalidSelectCompany}
            placeholderText={$_("buyerDetails.enterCompanyName")}
            customClasses="mt-0"
        />
    </div>
</div>

<style>
    * :global(.search-container .input_wrapper > input) {
        padding-left: 3rem;
    }
</style>

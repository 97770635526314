<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { Button, Tooltip as TwoUITooltip } from "@two-ui/core";
    import Fa from "svelte-fa";
    import { faCircleQuestion, faCopy } from "@fortawesome/free-regular-svg-icons";
    import { faSpinner } from "@fortawesome/free-solid-svg-icons";
    import { navigate } from "svelte-routing";

    import utils from "@/utilsV2/currency";
    import type { CurrencyCode } from "@/static/types";
    import ToolTip from "@/components/tooltip/ToolTip.svelte";
    import Modal from "@/components/Elements/Modal.svelte";
    import type { ICreditLimit } from "@/static/types";
    import { selectedCompanyName } from "@/store/merchant/orderCreate.store";
    import RemainingLimitsModal from "./RemainingLimitsModal.svelte";

    export let companyInfo: ICreditLimit = null;
    export let companyInfoLoading: boolean = false;
    export let currency: CurrencyCode = "EUR";

    let netTermsLimit = 0;
    let maxOrderValue = 0;
    let buyerRecourseLimit = null;

    let isCopiedTooltipOpened = false;
    let showErrorState = false;
    let showErrorInfoState = false;
    let buyerNotFound = false;

    const TROUBLESHOOT_LINK =
        "https://twoinc.notion.site/Troubleshooting-Credit-Limits-17b29959c003800d94d9f0a8ff768a3c";
    const BUYER_PORTAL_LINK = "/auth/buyer/login";

    let showModal = false;

    function goToBuyerPortal() {
        window.open(BUYER_PORTAL_LINK, "_blank");
    }

    function showInvoicesPage() {
        console.log("Not implemented yet.");
    }

    function handleErrorState() {
        showErrorState = false;
        showErrorInfoState = false;

        buyerNotFound =
            companyInfo.declineReason === "Could not retrieve a credit report for the buyer.";

        if (buyerRecourseLimit == 0) {
            showErrorState = true;
        } else if (companyInfo.isBuyerInArrears || buyerNotFound) {
            showErrorInfoState = true;
        }
    }

    function getRecourseLimit(recourseLimits: any) {
        if (recourseLimits === null) {
            return recourseLimits;
        }

        const buyerLimit = recourseLimits.buyer_limit.available_limit;
        const merchantLimit = recourseLimits.merchant_buyer_limit.available_limit;

        return Math.min(parseFloat(buyerLimit), parseFloat(merchantLimit)).toString();
    }

    $: {
        if (companyInfo !== null && companyInfo !== undefined) {
            netTermsLimit = companyInfo.availableCreditLimit;
            maxOrderValue = companyInfo.maxSingleOrderValue;
            buyerRecourseLimit = getRecourseLimit(companyInfo.recourseLimits);
            handleErrorState();
        }
    }

    $: if (companyInfoLoading) {
        showErrorState = false;
        showErrorInfoState = false;
    }
</script>

<section
    class="flex flex-col p-4 rounded-lg border border-Border-General-Primary"
    class:error-info={showErrorInfoState}
    class:error-state={showErrorState}
>
    <div class="flex items-center gap-1">
        <div class="title">{$_("order.new.limits.remainingLimits")}</div>
        <ToolTip
            width="275px"
            position="left-up"
            placement="start"
            content={$_("order.new.limits.tooltipContent")}
        >
            <Fa size="sm" icon={faCircleQuestion} color="var(--primary-500)" />
        </ToolTip>
    </div>
    {#if companyInfoLoading}
        <div class="m-auto animate-spin">
            <Fa size="2x" icon={faSpinner} color="var(--ctnGeneralTertiary)" />
        </div>
        <hr class="mt-2 mb-4" />
    {:else}
        {#if companyInfo}
            {#if buyerNotFound}
                <div class="text-Content-General-Secondary text-sm">
                    <p class="mb-4">
                        {$selectedCompanyName}{$_("order.new.limits.cantBeIdentified")}
                    </p>
                    <p class="mb-2">{$_("order.new.limits.weSuggestLiveChat")}</p>
                </div>
            {:else if companyInfo.isBuyerInArrears}
                <div class="text-Content-General-Secondary text-sm">
                    <p class="mb-4">
                        {$selectedCompanyName}{$_("order.new.limits.isCurrentlyBlocked")}
                    </p>
                    <p class="mb-2">
                        {$_("order.new.limits.thisMayIncludeInvoices")}
                        <button class="link-button" on:click={goToBuyerPortal}
                            >{$_("order.new.limits.portal")}</button
                        >
                    </p>
                </div>
            {:else}
                <div class="mb-4 text-content-general-tertiary troubleshoot-text">
                    <a href={TROUBLESHOOT_LINK} target="_blank"
                        >{$_("order.new.limits.troubleshootText")}</a
                    >{$_("order.new.limits.troubleshootSuffix")}
                </div>
                <div class="flex flex-col gap-2 grow">
                    <div class="limit-items">
                        <div class="limit-item-text">
                            {$_("order.new.limits.netTermsLimitText")}
                        </div>
                        <div class="limit-item-value">
                            {utils.formatCurrency(currency)(netTermsLimit)}
                        </div>
                    </div>
                    <div class="limit-items">
                        <div class="limit-item-text">
                            {$_("order.new.limits.maxOrderValueText")}
                        </div>
                        <div class="limit-item-value">
                            {utils.formatCurrency(currency)(maxOrderValue)}
                        </div>
                    </div>
                    {#if buyerRecourseLimit !== null}
                        <div class="limit-items">
                            <div class="limit-item-text">
                                {$_("order.new.limits.recourseLimitText")}
                            </div>
                            <div class="limit-item-value">
                                {utils.formatCurrency(currency)(buyerRecourseLimit)}
                            </div>
                        </div>
                    {/if}
                </div>
            {/if}
        {:else}
            <div class="mb-4 text-Content-General-Tertiary troubleshoot-text grow">
                {$_("order.new.limits.enterDetailsText")}
            </div>
        {/if}
        <hr class="mt-2 mb-4" />
        <div class="flex gap-2">
            {#if companyInfo}
                {#if !buyerNotFound}
                    {#if companyInfo.isBuyerInArrears}
                        <Button
                            size="sm"
                            variant="secondaryColor"
                            content={$_("order.new.limits.seeInvoices")}
                            on:click={showInvoicesPage}
                        />
                    {:else}
                        <Button
                            size="sm"
                            variant="secondaryColor"
                            content={$_("order.new.limits.increaseLimitsText")}
                            on:click={() => (showModal = true)}
                        />
                        <!-- Hiding Change Currency button for now -->
                        <!-- <Button
                            id="send_order"
                            size="sm"
                            variant="secondaryGray"
                            content={$_("order.new.limits.changeCurrencyText")}
                        /> -->
                    {/if}
                {/if}
            {:else}
                <ToolTip
                    width="275px"
                    position="left-up"
                    placement="start"
                    content={$_("order.new.limits.enterInfoTooltip")}
                >
                    <Button
                        size="sm"
                        variant="secondaryColor"
                        content={$_("order.new.limits.increaseLimitsText")}
                        disabled={true}
                    />
                </ToolTip>
            {/if}
        </div>
    {/if}
    <RemainingLimitsModal {showModal} {buyerRecourseLimit} {companyInfo} />
</section>

<style lang="scss">
    section {
        width: 363px;
        min-height: 200px;
    }

    .title {
        color: var(--ctnGeneralPrimary);
        font-size: 1rem;
        font-weight: 700;
    }

    .troubleshoot-text {
        font-size: 12px;
        font-weight: 400;
    }

    .grow {
        flex-grow: 1;
    }

    .limit-items {
        display: flex;
        padding: 4px 8px;
        gap: 8px;
        border-radius: 4px;
        background: var(--bgGeneralTertiary);
        justify-content: space-between;
    }

    .limit-item-text {
        color: var(--ctnGeneralTertiary);
        font-size: 14px;
        font-weight: 500;
    }

    .limit-item-value {
        color: var(--ctnGeneralSecondary);
        font-size: 16px;
        font-weight: 700;
    }

    a {
        color: var(--ctnInteractiveSemanticHighlightDefault);
    }

    .change-limit-link {
        display: flex;
        padding: 8px 12px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: var(--bgSemanticHighlightTertiary);
        color: var(--ctnSemanticHighlightPrimary);
        cursor: pointer;
    }

    .error-info {
        border-color: var(--borderSemanticWarningSecondary);
        background-color: var(--bgSemanticWarningTertiary);
    }

    .error-state {
        border-color: var(--borderSemanticErrorPrimary);
    }
    .link-button {
        background: none;
        border: none;
        color: var(--ctnInteractiveSemanticHighlightDefault);
        cursor: pointer;
        padding: 0;
        font: inherit;
    }
</style>

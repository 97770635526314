<script lang="ts">
    import { isLoading } from "svelte-i18n";
    import { SvelteUIProvider } from "@two-ui/core";

    import Modal from "./components/modals/Modal.svelte";
    import Notifications from "./components/notification/Notifications.svelte";
    import layoutRoutes from "./routes/app.routes";
    import Router from "./routes/CustomRouter.svelte";
    import "./services/i18n";
    import ChatSupport from "./components/chatSupport/ChatSupport.svelte";
    import { initFingerprint } from "./services/fingerprint";
    import { getMerchantTheme } from "./static/colours/theme";
    import environment, { getEnvironmentName } from "./services/environment";
    import packageJson from "../package.json";

    const newTheme = getMerchantTheme(environment.branding?.displayName);
</script>

<svelte:head>
    <title>{environment.branding?.title}</title>
    <link rel="icon" type="image/svg" href={environment.branding?.faviconUrl} />

    {#if environment.branding?.hasGoogleTagManager}
        <!-- Google Tag Manager -->
        <script>
            // T-19750 remove tracking for ANB for now
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, "script", "dataLayer", "GTM-PK39R3D");
        </script>
        <!-- End Google Tag Manager -->
    {/if}
</svelte:head>

<!-- Zendesk Chat Widget -->
{#if environment.branding?.hasSupportChat}
    <ChatSupport />
{/if}
<!-- End Zendesk Chat Widget -->

<svelte:window on:load={initFingerprint} />

<SvelteUIProvider
    theme={newTheme}
    override={{
        button: {
            fontWeight: "inherit"
        }
    }}
>
    <main class="h-full">
        {#if !$isLoading}
            <Modal />
            <Router routes={layoutRoutes} />
            <Notifications />
        {/if}
    </main>
    <div id="app-version">v.{packageJson.version} {getEnvironmentName()}</div>
</SvelteUIProvider>

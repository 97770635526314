<script lang="ts">
    import { _ } from "svelte-i18n";
    import { TextInput } from "@two-ui/core";

    import PhoneInput from "@/components/authentication/PhoneInput.svelte";

    export let representative = {
        first_name: "",
        last_name: "",
        email: ""
    };

    export let invalid = {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: ""
    };
    export let phoneNumberWithCode = "";
    export let phoneNumber = "";
    export let phoneInput;
</script>

<div id="representative-details" class="mt-4 details-container">
    <p class="font-bold">{$_("buyerDetails.representativeDetails")}</p>
    <div class="flex gap-4">
        <div class="w-full mt-5">
            <TextInput
                label={$_("buyerDetails.firstName")}
                id="customer_first_name"
                size="md"
                placeholder={$_("buyerDetails.enterFirstName")}
                bind:value={representative.first_name}
                bind:error={invalid.first_name}
                required={true}
            />
        </div>
        <div class="w-full mt-5">
            <TextInput
                label={$_("buyerDetails.lastName")}
                id="customer_last_name"
                size="md"
                placeholder={$_("buyerDetails.enterLastName")}
                bind:value={representative.last_name}
                bind:error={invalid.last_name}
                required={true}
            />
        </div>
    </div>
    <div class="flex pt-4 mb-4 gap-4">
        <div class="w-full mt-3">
            <div class="flex items-center mb-1">
                <label
                    class="label text-sm text-gray-700"
                    class:invalid={invalid.phone_number}
                    for=""
                >
                    {$_("buyerDetails.phoneNumber")}
                    <span class="text-main" class:invalid={invalid.phone_number}>*</span>
                </label>
            </div>
            <div class="phone_number_container" class:with-error={invalid.phone_number}>
                <PhoneInput
                    placeholder={$_("buyerDetails.enterPhoneNumber")}
                    disable={false}
                    useCustomInput={true}
                    bind:phoneNumberWithCode
                    bind:phoneNumber
                    bind:this={phoneInput}
                    invalid={invalid.phone_number}
                    on:input={() => {
                        invalid.phone_number = "";
                    }}
                />
            </div>
            {#if invalid.phone_number}
                <p class="mt-1 text-sm invalid">{invalid.phone_number}</p>
            {/if}
        </div>
        <div class="w-full mt-3">
            <TextInput
                label={$_("buyerDetails.email")}
                id="customer_email"
                size="md"
                placeholder={$_("buyerDetails.enterEmail")}
                bind:value={representative.email}
                bind:error={invalid.email}
                required={true}
            />
        </div>
    </div>
</div>

<style>
    .invalid {
        color: var(--ctnSemanticErrorPrimary);
    }
</style>

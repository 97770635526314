<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button, ActionIcon, Tooltip as TwoUITooltip } from "@two-ui/core";
    import Fa from "svelte-fa";
    import { faCircleQuestion, faCopy } from "@fortawesome/free-regular-svg-icons";
    import { navigate } from "svelte-routing";

    import Modal from "@/components/Elements/Modal.svelte";

    export let showModal = false;
    export let buyerRecourseLimit = null;
    export let companyInfo: ICreditLimit = null;

    let isCopiedTooltipOpened = false;

    const CREDIT_BUILDER_LINK = "https://checkout.two.inc/credit_limit_change/credit_builder";
    const LEARN_ABOUT_RECOURSE_LINK =
        "https://docs.google.com/forms/d/e/1FAIpQLScE9VfDdzN2QTJcKeiN8iHxng72lgHNXTCRrDfazgG2hZ-1qA/viewform";
    const CUSTOMER_PAGE_ROUTE = "/merchant/customers/";

    function copyToClipboard() {
        isCopiedTooltipOpened = true;
        navigator.clipboard.writeText(CREDIT_BUILDER_LINK);
        setTimeout(() => {
            isCopiedTooltipOpened = false;
        }, 3000);
    }

    function goToCustomersPage() {
        navigate(
            `${CUSTOMER_PAGE_ROUTE}${companyInfo.buyerCompanyNumber}?country=${companyInfo.buyerCountryCode}`
        );
    }
</script>

<Modal
    bind:show={showModal}
    onClose={() => (showModal = false)}
    title={$_("order.new.limits.increaseClientsLimitText")}
>
    <div>
        {#if buyerRecourseLimit !== null}
            <div>
                <p>{$_("order.new.limits.yourTwoOptions")}</p>
                <ol>
                    <li>{$_("order.new.limits.optionOne")}</li>
                    <li>{$_("order.new.limits.optionTwo")}</li>
                </ol>
            </div>
        {:else}
            <div>
                <p>{$_("order.new.limits.creditBuilderText")}</p>
            </div>
        {/if}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="mt-4 change-limit-link">
            <a href={CREDIT_BUILDER_LINK} target="_blank">{CREDIT_BUILDER_LINK}</a>
            <TwoUITooltip
                opened={isCopiedTooltipOpened}
                label={$_("customer.creditLimit.linkCopied")}
                position="bottom"
            >
                <button id="copy-credit-builder-link" on:click={copyToClipboard}>
                    <Fa size="sm" icon={faCopy} color="var(--ctnSemanticHighlightPrimary)" />
                </button>
            </TwoUITooltip>
        </div>

        {#if buyerRecourseLimit === null}
            <div class="mt-6">
                <div class="mb-4 text-ctnGeneralSecondary text-xl font-medium leading-7">
                    {$_("order.new.limits.wantInstantIncreases")}
                </div>
                <p>{$_("order.new.limits.notSetupForRecourseText")}</p>
            </div>
        {/if}
    </div>
    <div slot="footer" class="flex gap-2 mt-14">
        {#if buyerRecourseLimit === null}
            <Button
                size="md"
                variant="secondaryGray"
                content={$_("order.new.limits.learnAboutRecourse")}
                on:click={() => window.open(LEARN_ABOUT_RECOURSE_LINK, "_blank")}
            />
        {:else}
            <Button
                size="md"
                variant="secondaryGray"
                content={$_("order.new.limits.goToCustomersPage")}
                on:click={goToCustomersPage}
            />
        {/if}
        <Button
            size="md"
            variant="primary"
            content={$_("order.new.limits.goBack")}
            on:click={() => (showModal = false)}
        />
    </div>
</Modal>

<script lang="ts">
    import { _ } from "svelte-i18n";

    import Selector from "@/components/Elements/Selector.svelte";

    let paymentMethods: string[] = ["Standard terms (single payment)"];
    let paymentMethod: string = paymentMethods[0];
    let filterItems: string[] = ["14 days", "30 days", "45 days", "60 days", "90 days"];

    export let due_in_days: string = "";
    export let invalidDueInDays = "";
</script>

<section>
    <div class="flex items-center gap-1">
        <div class="text-Content-General-Primary font-bold">
            {$_("order.new.limits.repaymentMethod")}
        </div>
    </div>
    <div class="mb-4 text-Content-General-Tertiary text-xs">
        {$_("order.new.limits.choosePaymentMethod")}
    </div>
    <div class="flex gap-[1rem]">
        <div class="w-full mt-3 pr-2">
            <Selector
                id="payment_method"
                label={$_("order.new.limits.paymentMethod")}
                required
                showArrow={true}
                options={paymentMethods}
                bind:value={paymentMethod}
                placeholder="-"
                size="44px"
                backgroundColor="white"
                showInvalidError={true}
            />
        </div>
    </div>
    <div class="flex pt-2 gap-[1rem]">
        <div class="w-full mt-3 pr-2" class:with-error={invalidDueInDays}>
            <Selector
                id="invoice_payment_terms"
                label={$_("order.new.limits.repaymentTerms")}
                required
                showArrow={true}
                options={filterItems}
                bind:value={due_in_days}
                placeholder="-"
                size="44px"
                backgroundColor="white"
                showInvalidError={true}
                invalid={invalidDueInDays}
            />
        </div>
    </div>
</section>
